<template>
  <div class="container">
    <div class="box">
      <span>1</span>
      <span>2</span>
      <span>3</span>
      <span>4</span>
      <span>5</span>
      <span>6</span>
      <span>7</span>
      <span>8</span>
      <span>9</span>
      <span>10</span>
      <span>11</span>
      <span>12</span>
      <span>13</span>
      <span>14</span>

      <span>1</span>
      <span>2</span>
      <span>3</span>
      <span>4</span>
      <span>5</span>
      <span>6</span>
      <span>7</span>
      <span>8</span>
      <span>9</span>
      <span>10</span>
    </div>
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>
.container {
  width: 1200px;
  border: 1px solid #eee;
  border-radius: 8px;
  margin: 20px auto;
  overflow: hidden;
  .box {
    width: max-content;
    animation: scroll 6s linear infinite;
    animation-fill-mode: forwards;
    span {
      box-sizing: border-box;
      display: inline-block;
      width: 123px;
      height: 40px;
      border: 1px solid #000;
      margin: 4px;
    }
  }
}
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-1834px);
  }
}
</style>